"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.radioVariants = void 0;
var constants_1 = require("../../utils/constants");
exports.radioVariants = [
    {
        label: 'Добавить вариант',
        type: constants_1.optionTypes.default,
    },
    {
        label: 'Добавить пустое значение',
        type: constants_1.optionTypes.emptyValue,
    },
    {
        label: 'Ввод значения пользователем',
        type: constants_1.optionTypes.userInput,
    },
];
