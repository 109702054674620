"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodesBetweenBoundary = exports.updateBoundaryNodes = exports.hasBoundaryInSelectedNodes = exports.highlightBoundaries = exports.removeBoundaryPlaceholder = exports.highlightBoundaryNode = exports.createBoundaryContentNode = exports.createBoundaryRightElement = exports.createBoundaryLeftElement = exports.clearBoundaryContentAttrs = exports.setBoundaryContentAttrs = exports.setBoundaryAttrs = exports.getBoundaryType = void 0;
var uuid_1 = require("uuid");
var constants_1 = require("../../../constants");
var common_1 = require("../../../utils/common");
var nodeUtils_1 = require("./nodeUtils");
var placeholderUtils_1 = require("./placeholderUtils");
function getBoundaryType(element) {
    if (common_1.CheckUtils.isBoundaryLeft(element)) {
        return 'left';
    }
    if (common_1.CheckUtils.isBoundaryContent(element)) {
        return 'content';
    }
    if (common_1.CheckUtils.isBoundaryRight(element)) {
        return 'right';
    }
}
exports.getBoundaryType = getBoundaryType;
function setBoundaryAttrs(element, id, type) {
    if (id === void 0) { id = (0, uuid_1.v4)(); }
    var newType = type !== null && type !== void 0 ? type : getBoundaryType(element);
    element.setAttribute(constants_1.PH_ATTRS.boundaryId, id);
    // нужно использовать общий для ПХ и ПП атрибут
    element.setAttribute(constants_1.PH_ATTRS.id, id);
    if (newType === 'content') {
        setBoundaryContentAttrs(element, id);
    }
    else if (newType === 'right') {
        element.setAttribute('id', common_1.AttrUtils.createBoundaryRightPhId(id));
    }
    else {
        element.setAttribute('id', common_1.AttrUtils.createPhId(id));
    }
    return id;
}
exports.setBoundaryAttrs = setBoundaryAttrs;
function setBoundaryContentAttrs(element, id) {
    element.classList.add(constants_1.BOUNDARY_CLASS_NAMES.content);
    element.setAttribute('id', (0, uuid_1.v4)());
    element.setAttribute('onclick', "window.showBoundaryPhPanel('".concat(id, "')"));
    element.setAttribute('onmouseover', "window.showBoundaryTooltip('".concat(id, "')"));
    element.setAttribute('onmouseout', 'window.hideBoundaryTooltip()');
}
exports.setBoundaryContentAttrs = setBoundaryContentAttrs;
function clearBoundaryContentAttrs(element) {
    element.classList.remove(constants_1.BOUNDARY_CLASS_NAMES.content, constants_1.CLASS_NAMES.warning, constants_1.CLASS_NAMES.editing);
    element.removeAttribute(constants_1.PH_ATTRS.boundaryId);
    element.removeAttribute(constants_1.PH_ATTRS.id);
    element.removeAttribute('onmouseover');
    element.removeAttribute('onmouseout');
    element.removeAttribute('onclick');
}
exports.clearBoundaryContentAttrs = clearBoundaryContentAttrs;
function createBoundaryLeftElement(id) {
    var element = (0, placeholderUtils_1.createPlaceholderElement)();
    element.classList.add(constants_1.BOUNDARY_CLASS_NAMES.placeholder, constants_1.BOUNDARY_CLASS_NAMES.left);
    setBoundaryAttrs(element, id, 'left');
    element.innerHTML = 'Передаваемое поле';
    return element;
}
exports.createBoundaryLeftElement = createBoundaryLeftElement;
function createBoundaryRightElement(id) {
    var element = (0, placeholderUtils_1.createPlaceholderElement)();
    setBoundaryAttrs(element, id, 'right');
    element.classList.add(constants_1.BOUNDARY_CLASS_NAMES.placeholder, constants_1.BOUNDARY_CLASS_NAMES.right);
    element.innerHTML = constants_1.ZWNJ;
    return element;
}
exports.createBoundaryRightElement = createBoundaryRightElement;
// пробел в дефолтном значении параметра text нужен!
function createBoundaryContentNode(id, text) {
    if (text === void 0) { text = ' '; }
    var element = (0, nodeUtils_1.createNewInlineText)(text);
    element.classList.add(constants_1.CLASS_NAMES.editing);
    setBoundaryAttrs(element, id, 'content');
    return element;
}
exports.createBoundaryContentNode = createBoundaryContentNode;
var highlightBoundaryNode = function (boundaryId, node, classList) {
    var _a;
    if (classList === void 0) { classList = []; }
    if (!node || common_1.CheckUtils.hasClassName(node, 'placeholder')) {
        return;
    }
    (_a = node.classList).add.apply(_a, __spreadArray([constants_1.BOUNDARY_CLASS_NAMES.content], classList, false));
    setBoundaryAttrs(node, boundaryId, 'content');
};
exports.highlightBoundaryNode = highlightBoundaryNode;
function removeBoundaryPlaceholder(deletedPh) {
    var _a;
    var leftSpan = deletedPh.findDOM();
    var rightSpan = deletedPh.findRightBoundaryDOM();
    rightSpan === null || rightSpan === void 0 ? void 0 : rightSpan.remove();
    leftSpan === null || leftSpan === void 0 ? void 0 : leftSpan.remove();
    var nodes = deletedPh.findBoundaryContentElements();
    nodes.forEach(function (node) {
        clearBoundaryContentAttrs(node);
    });
    var lastNode = nodes[nodes.length - 1];
    if (lastNode && common_1.TypeUtils.isInlineTextWithoutBoundary(lastNode.nextSibling)) {
        lastNode.nextSibling.innerHTML = common_1.TextUtils.removeZwnj(lastNode.nextSibling.innerHTML);
        lastNode.append.apply(lastNode, Array.from(lastNode.nextSibling.childNodes));
        lastNode.nextSibling.remove();
    }
    var firstNode = nodes[0];
    if (firstNode && common_1.TypeUtils.isInlineTextWithoutBoundary(firstNode.previousSibling)) {
        firstNode.innerHTML = common_1.TextUtils.removeZwnj(firstNode.innerHTML);
        (_a = firstNode.previousSibling).append.apply(_a, Array.from(firstNode.childNodes));
        firstNode.remove();
    }
}
exports.removeBoundaryPlaceholder = removeBoundaryPlaceholder;
function getBoundariesNode(id, parentNode, nodeLeft) {
    var _a;
    var nodes = [];
    var currentNodeLeft = (_a = nodeLeft !== null && nodeLeft !== void 0 ? nodeLeft : parentNode === null || parentNode === void 0 ? void 0 : parentNode.querySelector(constants_1.CLASSES.inlineText)) !== null && _a !== void 0 ? _a : null;
    if (!currentNodeLeft || !parentNode) {
        return { nodes: nodes, isEnd: true };
    }
    var boundaryNodeRight = common_1.DomUtils.selectElementById(common_1.AttrUtils.createBoundaryRightPhId(id));
    var isEnd = false;
    while (currentNodeLeft && !isEnd) {
        if (currentNodeLeft !== boundaryNodeRight) {
            nodes.push(currentNodeLeft);
            currentNodeLeft = currentNodeLeft.nextElementSibling;
        }
        else {
            isEnd = true;
        }
    }
    return { nodes: nodes, isEnd: isEnd };
}
function highlightBoundaries(nodeLeft) {
    var id = common_1.AttrUtils.getPhId(nodeLeft);
    var currentParentNode = common_1.GetUtils.getParentElement(nodeLeft, common_1.CheckUtils.isParagraphNode);
    if (!id || !currentParentNode) {
        return;
    }
    var results = getBoundariesNode(id, currentParentNode, nodeLeft);
    var isEnd = results.isEnd;
    var nodes = results.nodes;
    while (!isEnd && currentParentNode) {
        currentParentNode = currentParentNode.nextElementSibling;
        var nodeLeft_1 = currentParentNode === null || currentParentNode === void 0 ? void 0 : currentParentNode.querySelector(constants_1.CLASSES.inlineText);
        var results_1 = getBoundariesNode(id, currentParentNode, nodeLeft_1);
        nodes.push.apply(nodes, results_1.nodes);
        isEnd = results_1.isEnd;
    }
    nodes.forEach(function (node) {
        (0, exports.highlightBoundaryNode)(id, node, [constants_1.CLASS_NAMES.creating]);
    });
}
exports.highlightBoundaries = highlightBoundaries;
function hasBoundaryInSelectedNodes() {
    var _a;
    var hasBoundary = false;
    var selectedNodeList = common_1.GetUtils.getSelectedNodeList();
    if (!selectedNodeList) {
        return hasBoundary;
    }
    for (var i = 0; i < selectedNodeList.length; i++) {
        var node = selectedNodeList[i];
        if (common_1.CheckUtils.isBoundary(node)) {
            hasBoundary = true;
            break;
        }
        if (common_1.TypeUtils.isTextNode(node) && common_1.CheckUtils.isBoundary((_a = (0, nodeUtils_1.getTargetValues)()) === null || _a === void 0 ? void 0 : _a.tInlineText)) {
            hasBoundary = true;
            break;
        }
    }
    return hasBoundary;
}
exports.hasBoundaryInSelectedNodes = hasBoundaryInSelectedNodes;
function updateBoundaryNodes(block) {
    var boundaries = {};
    var nodes = common_1.DomUtils.selectBoundaries(block);
    nodes === null || nodes === void 0 ? void 0 : nodes.forEach(function (node) {
        var id = common_1.AttrUtils.getPhId(node);
        if (!id) {
            return;
        }
        if (!boundaries[id]) {
            boundaries[id] = {
                content: [],
                emptyContent: [],
                left: null,
                right: null,
            };
        }
        if (common_1.CheckUtils.isBoundaryLeft(node)) {
            boundaries[id].left = node;
        }
        if (common_1.CheckUtils.isBoundaryRight(node)) {
            boundaries[id].right = node;
        }
        if (common_1.CheckUtils.isBoundaryContent(node)) {
            // если в передаваемом поле ПХ, то ПП перед ним или после него может быть пустым
            if (common_1.CheckUtils.isEmptyText(node) &&
                !common_1.CheckUtils.isPlaceholder(node.previousSibling) &&
                !common_1.CheckUtils.isPlaceholder(node.nextSibling)) {
                boundaries[id].emptyContent.push(node);
            }
            else {
                // при удалении части ПП, состоящей из нескольких абзацев, нужно склеить оставшиеся соседние ноды
                if (common_1.TypeUtils.isElement(node.nextSibling) && common_1.CheckUtils.isBoundaryContent(node.nextSibling)) {
                    (0, nodeUtils_1.appendChildNodes)(node, node.nextSibling);
                    node.nextSibling.remove();
                }
                boundaries[id].content.push(node);
            }
        }
    });
    Object.entries(boundaries).forEach(function (_a) {
        var _b, _c, _d, _e;
        var id = _a[0], values = _a[1];
        var contentCount = values.content.length;
        values.emptyContent.forEach(function (element) {
            element.remove();
        });
        if (values.left && !common_1.CheckUtils.isBoundaryContent(values.left.nextSibling)) {
            if (common_1.CheckUtils.isEmptyText(values.left.previousSibling)) {
                (_b = values.left.previousSibling) === null || _b === void 0 ? void 0 : _b.remove();
            }
            (_c = values.left) === null || _c === void 0 ? void 0 : _c.remove();
        }
        if (values.right && !common_1.CheckUtils.isBoundaryContent(values.right.previousSibling)) {
            if (common_1.CheckUtils.isEmptyText(values.right.nextSibling)) {
                (_d = values.right.nextSibling) === null || _d === void 0 ? void 0 : _d.remove();
            }
            (_e = values.right) === null || _e === void 0 ? void 0 : _e.remove();
        }
        if (contentCount && !values.right) {
            var rightNode = createBoundaryRightElement(id);
            values.content[contentCount - 1].after(rightNode);
            if (!rightNode.nextSibling) {
                rightNode.after((0, nodeUtils_1.createNewInlineText)());
            }
        }
        if (contentCount && !values.left) {
            var leftNode = createBoundaryLeftElement(id);
            values.content[0].before(leftNode);
            if (!leftNode.previousSibling) {
                leftNode.before((0, nodeUtils_1.createNewInlineText)());
            }
        }
    });
}
exports.updateBoundaryNodes = updateBoundaryNodes;
// ??
function findNextNode(prevNode, nextNode, boundaryId, nodesBetween) {
    if (nextNode) {
        if (common_1.CheckUtils.isBoundaryRight(nextNode) && nextNode.getAttribute('data-boundary') === boundaryId) {
            return nodesBetween;
        }
        nodesBetween.push(nextNode);
        return findNextNode(nextNode, nextNode.nextSibling, boundaryId, nodesBetween);
    }
    if (!prevNode)
        return nodesBetween;
    // достигли конца параграфа, но не нашли правую границу
    var parentParagraph = common_1.GetUtils.getParentElement(prevNode, common_1.CheckUtils.isParagraphNode);
    if (parentParagraph && parentParagraph.nextSibling) {
        return findNextNode(null, parentParagraph.nextSibling.firstChild, boundaryId, nodesBetween);
    }
    return nodesBetween;
}
// ??
function getNodesBetweenBoundary(placeholder) {
    var boundaryId = placeholder.getAttribute('data-boundary');
    var nodesBetween = [];
    findNextNode(placeholder, placeholder.nextSibling, boundaryId, nodesBetween);
    return nodesBetween;
}
exports.getNodesBetweenBoundary = getNodesBetweenBoundary;
