"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTarget = exports.insertPlaceholderToDom = exports.getEditingPlaceholder = exports.createPlaceholder = exports.createPlaceholderElement = void 0;
var uuid_1 = require("uuid");
var common_1 = require("../../../utils/common");
var jsonParse_1 = require("../../../utils/jsonParse");
var constants_1 = require("../../../constants");
var boundaryUtils_1 = require("./boundaryUtils");
var nodeUtils_1 = require("./nodeUtils");
var textEditUtils_1 = require("./textEditUtils");
function createPlaceholderElement() {
    var element = document.createElement('span');
    element.classList.add(constants_1.CLASS_NAMES.placeholder, constants_1.CLASS_NAMES.aggregation);
    element.setAttribute('contentEditable', 'false');
    return element;
}
exports.createPlaceholderElement = createPlaceholderElement;
function createPlaceholder(id) {
    var element = createPlaceholderElement();
    common_1.AttrUtils.setPlaceholderIdAttrs(element, id);
    element.setAttribute('onclick', 'window.textEditor.onPlaceholderClick(this)');
    return element;
}
exports.createPlaceholder = createPlaceholder;
function getEditingPlaceholder(phId, placeholders) {
    var selectedPh = null;
    placeholders === null || placeholders === void 0 ? void 0 : placeholders.forEach(function (ph) {
        if (ph.id === phId) {
            ph.setEditing(true);
            selectedPh = ph;
        }
        else {
            ph.setEditing(false);
        }
    });
    return selectedPh;
}
exports.getEditingPlaceholder = getEditingPlaceholder;
function insertPlaceholderToDom(phNode, targets, phName) {
    var tInlineText = targets.tInlineText, pointOfInsert = targets.pointOfInsert;
    var leftHTMLPart = tInlineText.innerHTML.slice(0, pointOfInsert);
    if (phName) {
        leftHTMLPart = leftHTMLPart.replace(phName, '');
    }
    var rightHTMLPart = tInlineText.innerHTML.slice(pointOfInsert);
    var normalizedLeftPart = (0, textEditUtils_1.normalizeHtml)(leftHTMLPart);
    var normalizedRightPart = (0, textEditUtils_1.normalizeHtml)(rightHTMLPart);
    var leftInlineSpan = (0, nodeUtils_1.createNewInlineText)(normalizedLeftPart);
    var rightInlineSpan = (0, nodeUtils_1.createNewInlineText)(normalizedRightPart);
    if (common_1.CheckUtils.isBoundaryContent(tInlineText)) {
        var id = common_1.AttrUtils.getPhId(tInlineText);
        (0, boundaryUtils_1.setBoundaryAttrs)(leftInlineSpan, id, 'content');
        (0, boundaryUtils_1.setBoundaryAttrs)(rightInlineSpan, id, 'content');
    }
    tInlineText.before(leftInlineSpan);
    tInlineText.before(phNode);
    tInlineText.remove();
    phNode.after(rightInlineSpan);
    return rightInlineSpan;
}
exports.insertPlaceholderToDom = insertPlaceholderToDom;
function updateTarget(target) {
    var _a;
    var newTarget = Array.isArray(target) ? target : (_a = (0, jsonParse_1.jsonParse)(target)) !== null && _a !== void 0 ? _a : [];
    return newTarget.map(function (v) { return (__assign(__assign({}, v), { id: (0, uuid_1.v4)() })); });
}
exports.updateTarget = updateTarget;
