"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearChildrenRelations = void 0;
var getDefaultAggregationName_1 = require("./getDefaultAggregationName");
var common_1 = require("./common");
var clearChildrenRelations = function (aggregation, aggregations) {
    var children = aggregations.filter(function (aggr) { return aggr.parent_id === aggregation.id; });
    var updatedAggregation = __assign(__assign({}, aggregation), { settings: undefined });
    var updatedChildrenAggregations = children.map(function (child) { return (__assign(__assign({}, child), { parent_id: undefined, name: child.initial_title || (0, getDefaultAggregationName_1.getDefaultAggregationName)(child), has_relations: true })); });
    children.filter(function (child) {
        if (child.name.indexOf("#") !== -1) {
            var childInDoc = common_1.DomUtils.selectPlaceholder(child.id);
            if (childInDoc)
                childInDoc.textContent = "".concat(child === null || child === void 0 ? void 0 : child.order, ". ").concat(child.initial_title || (0, getDefaultAggregationName_1.getDefaultAggregationName)(child));
        }
    });
    return __spreadArray(__spreadArray([], updatedChildrenAggregations, true), [updatedAggregation], false);
};
exports.clearChildrenRelations = clearChildrenRelations;
