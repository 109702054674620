"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidTlPlaceholder = exports.getInvalidPlaceholderValues = exports.getPhPanelData = exports.handleFilterOption = exports.templatedListPlaceholdersValueToDom = exports.findPlaceholdersOnPage = exports.updatePlaceholderDom = exports.removeDotExceptLast = exports.setMoneyValueFormat = exports.returnPlaceholderPayload = exports.isPlaceholderBankAcc = exports.parseExternalOptions = exports.resolvePlaceholderValue = void 0;
var constants_1 = require("../../shared/utils/constants");
var common_1 = require("../../shared/utils/common");
var jsonParse_1 = require("../../shared/utils/jsonParse");
var placeholderValueValidation_1 = require("./placeholderValueValidation");
var constants_2 = require("../../shared/constants");
function resolvePlaceholderValue(placeholder, values) {
    var result = values.find(function (val) { return val.placeholder_id === placeholder.id; });
    return common_1.PhValuesUtils.getPlaceholderValue(result);
}
exports.resolvePlaceholderValue = resolvePlaceholderValue;
function parseExternalOptions(placeholder, documentId) {
    try {
        if (!documentId)
            return [];
        var parsedUdmsOptions = typeof placeholder.udmsOptions === 'string' ? JSON.parse(placeholder.udmsOptions) : placeholder.udmsOptions;
        return (parsedUdmsOptions && parsedUdmsOptions.filter(function (x) { return x.documentId === documentId; })) || [];
    }
    catch (err) {
        console.error(err);
        return [];
    }
}
exports.parseExternalOptions = parseExternalOptions;
function isPlaceholderBankAcc(placeholder) {
    var dataFormat = placeholder.dataFormat;
    return !!dataFormat && typeof dataFormat === 'string' && dataFormat.toUpperCase().includes('BANKACCNUM');
}
exports.isPlaceholderBankAcc = isPlaceholderBankAcc;
function getValueString(value) {
    if (value === undefined || value === null) {
        return value;
    }
    return String(value);
}
function returnPlaceholderPayload(placeholder, value, elementId, placeholdersValue, attachmentDocumentID, createdDocumentID, templatedListPlaceholderId, valuesArray) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (elementId === void 0) { elementId = null; }
    if (placeholdersValue === void 0) { placeholdersValue = []; }
    if (attachmentDocumentID === void 0) { attachmentDocumentID = null; }
    if (createdDocumentID === void 0) { createdDocumentID = null; }
    if (templatedListPlaceholderId === void 0) { templatedListPlaceholderId = ''; }
    if (valuesArray === void 0) { valuesArray = []; }
    var documentId = location.pathname.split('/')[2];
    var placeholderOptions = (constants_1.PLACEHOLDER_OPTION_TYPES === null || constants_1.PLACEHOLDER_OPTION_TYPES === void 0 ? void 0 : constants_1.PLACEHOLDER_OPTION_TYPES.includes(placeholder === null || placeholder === void 0 ? void 0 : placeholder.type))
        ? parseExternalOptions(placeholder, documentId)
        : placeholder.options;
    if ((_a = placeholder.TLUdmsOptions) === null || _a === void 0 ? void 0 : _a.length) {
        placeholderOptions = placeholder.TLUdmsOptions;
    }
    var selectedOption = placeholderOptions === null || placeholderOptions === void 0 ? void 0 : placeholderOptions.find(function (opt) { return opt.id === value; });
    if (!selectedOption) {
        selectedOption = placeholderOptions === null || placeholderOptions === void 0 ? void 0 : placeholderOptions.find(function (opt) { return opt.text === value; });
    }
    var selectedOptionId = selectedOption ? selectedOption.id : '';
    var valueToSet = value;
    if (selectedOption) {
        var isBankAccType = isPlaceholderBankAcc(placeholder);
        var optionValue = isBankAccType ? selectedOption.id : selectedOption.text;
        valueToSet = !selectedOption.emptyValue ? optionValue : '';
        if (selectedOption.userInput) {
            valueToSet = value === selectedOption.id ? constants_1.defaultInputValues.userInput : value;
            if (placeholder.isRequired) {
                valueToSet = '';
            }
        }
    }
    // если ПХ из списка - placeholderId, у остальных ПХ - id
    var phId = (_b = placeholder.placeholderId) !== null && _b !== void 0 ? _b : placeholder.id;
    var index = (_c = placeholder.placeholderIndex) !== null && _c !== void 0 ? _c : 0;
    if (value === null && !selectedOptionId) {
        selectedOptionId = null;
    }
    else if (!selectedOptionId) {
        // если выбран ввод пользователем, selectedOptionId будет пустой, берем его из значений ПХ
        var phValue = placeholdersValue.find(function (obj) { return obj.placeholder_id === phId; });
        if (phValue) {
            selectedOptionId = common_1.PhValuesUtils.getOptionId(phValue, index);
        }
    }
    if (value instanceof Object && (value === null || value === void 0 ? void 0 : value.isUserInput)) {
        valueToSet = value.textValue;
        selectedOptionId = value.optionId;
    }
    var values = [];
    var items = [];
    if (templatedListPlaceholderId) {
        var tlPhValue = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.find(function (ph) { return ph.placeholder_id === phId; });
        var newValue = value;
        if (((_d = placeholder.TLUdmsOptions) === null || _d === void 0 ? void 0 : _d.length) || ((_e = placeholder.options) === null || _e === void 0 ? void 0 : _e.length)) {
            var valueContent_1 = tlPhValue === null || tlPhValue === void 0 ? void 0 : tlPhValue.valueContent;
            items = common_1.PhValuesUtils.isTLKeyValue(valueContent_1) ? (_f = valueContent_1.items) !== null && _f !== void 0 ? _f : [] : [];
            newValue = valueToSet;
            items[index] = {
                key: selectedOptionId,
                templatedListPlaceholderId: placeholder.TemplatedListPlaceholderId,
                order: index,
                value: valueToSet,
            };
        }
        if (tlPhValue) {
            values = (_g = tlPhValue.values) !== null && _g !== void 0 ? _g : [];
            if (newValue === null || newValue === undefined) {
                newValue = '';
            }
            values[index] = "".concat(newValue);
        }
        else {
            values.length = valuesArray.length;
            values.fill('');
            values[index] = "".concat(newValue);
        }
    }
    var valueContent = null;
    if (selectedOptionId) {
        valueContent = {
            discriminator: 'text-key-value',
            key: selectedOptionId,
            value: getValueString(valueToSet),
        };
        if (templatedListPlaceholderId) {
            valueContent = {
                discriminator: 'key-value-list',
                items: items,
            };
        }
    }
    var payload = [
        {
            attachmentDocumentID: attachmentDocumentID,
            createdDocumentID: createdDocumentID,
            templatedListPlaceholderId: templatedListPlaceholderId,
            valueContent: valueContent,
            values: values,
            placeholder_id: phId,
            optionId: selectedOptionId,
            value: getValueString(valueToSet),
        },
    ];
    Object.keys(placeholder.settings).forEach(function (key) {
        var newValue = '';
        var newOptionId = '';
        if (placeholder.settings[key] && placeholder.settings[key][selectedOptionId]) {
            // пока плейсхолдеры не имеют мультивыбора
            var parentOption = placeholder.settings[key][selectedOptionId][0];
            if (parentOption) {
                newValue = parentOption.emptyValue ? '' : parentOption.text;
                newOptionId = parentOption.id;
            }
        }
        var placeholderId = key;
        var valueContent = null;
        if (selectedOptionId) {
            valueContent = {
                discriminator: 'text-key-value',
                key: newOptionId,
                value: getValueString(newValue),
            };
        }
        payload.push({
            placeholder_id: placeholderId,
            value: getValueString(newValue),
            attachmentDocumentID: attachmentDocumentID,
            createdDocumentID: createdDocumentID,
            templatedListPlaceholderId: templatedListPlaceholderId,
            values: values,
            valueContent: valueContent,
            optionId: newOptionId, // старая версия
        });
    });
    return payload; // TODO: payload type should be equal PlaceholdersValue type
}
exports.returnPlaceholderPayload = returnPlaceholderPayload;
function setMoneyValueFormat(value) {
    if (value !== null && value !== '') {
        value = value.replaceAll(',', '.');
        value = value.replaceAll(/\s/g, '');
        value = value.replaceAll(/[^\d.-]/g, '');
        value = removeDotExceptLast(value);
        value = +value;
        value = value.toFixed(2);
    }
    else {
        value = '';
    }
    return value;
}
exports.setMoneyValueFormat = setMoneyValueFormat;
function removeDotExceptLast(inputString) {
    var lastCommaIndex = inputString.lastIndexOf('.');
    if (lastCommaIndex > -1) {
        return inputString.substring(0, lastCommaIndex).replaceAll('.', '') + inputString.substring(lastCommaIndex);
    }
    else {
        return inputString;
    }
}
exports.removeDotExceptLast = removeDotExceptLast;
function updatePlaceholderDom(placeholder, placeholderValue, placeholders) {
    var _a;
    if (!placeholder || !placeholderValue)
        return;
    var node = common_1.DomUtils.selectPlaceholder(placeholderValue.placeholder_id);
    var newVal = placeholder && (placeholder.type === 'attachment' || placeholder.subType === 'boundary')
        ? placeholder.title
        : common_1.PhValuesUtils.getPlaceholderValue(placeholderValue);
    if (placeholder === null || placeholder === void 0 ? void 0 : placeholder.customFormulaData) {
        var placeholderParameters = (_a = placeholder === null || placeholder === void 0 ? void 0 : placeholder.customFormulaData) === null || _a === void 0 ? void 0 : _a.parameters;
        var placeholderParametersIds_1 = placeholderParameters === null || placeholderParameters === void 0 ? void 0 : placeholderParameters.map(function (parameter) { return parameter === null || parameter === void 0 ? void 0 : parameter.placeholderId; });
        var relatedPlaceholders = placeholders === null || placeholders === void 0 ? void 0 : placeholders.filter(function (placeholder) {
            return placeholderParametersIds_1 === null || placeholderParametersIds_1 === void 0 ? void 0 : placeholderParametersIds_1.includes(placeholder === null || placeholder === void 0 ? void 0 : placeholder.id);
        });
        var isMoneyFormat = relatedPlaceholders === null || relatedPlaceholders === void 0 ? void 0 : relatedPlaceholders.some(function (relatedPlaceholder) { return (relatedPlaceholder === null || relatedPlaceholder === void 0 ? void 0 : relatedPlaceholder.dataFormat) === 'money'; });
        if (isMoneyFormat) {
            if (newVal !== null && newVal !== '') {
                newVal = "".concat(newVal).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                newVal = "".concat(newVal).replace('.', ',');
            }
        }
    }
    if (constants_1.moneyFormat.includes(placeholder.dataFormat)) {
        newVal = setMoneyValueFormat(newVal);
        if (newVal !== null && newVal !== '') {
            newVal = "".concat(newVal).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            newVal = "".concat(newVal).replace('.', ',');
        }
    }
    if (node !== null && node.innerText !== newVal) {
        node.innerText = newVal || '';
    }
}
exports.updatePlaceholderDom = updatePlaceholderDom;
// возвращает массив id плейсхолдеров, которые находятся на странице
// или null, если таких нет
function findPlaceholdersOnPage() {
    try {
        var phNodes = document.getElementsByClassName('placeholder');
        if (!phNodes || !phNodes.length)
            return null;
        var phNodesArray = Array.from(phNodes);
        var phIdsArray_1 = [];
        phNodesArray.forEach(function (ph) {
            var phId = ph.getAttribute('id') || '';
            if (phId && typeof phId === 'string') {
                phIdsArray_1.push(phId.slice(3));
            }
        });
        return phIdsArray_1.length ? phIdsArray_1 : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
exports.findPlaceholdersOnPage = findPlaceholdersOnPage;
function templatedListPlaceholdersValueToDom(phValues) {
    phValues === null || phValues === void 0 ? void 0 : phValues.forEach(function (phValue) {
        var placeholderId = phValue.placeholder_id;
        var phNodes = document.querySelectorAll("#ph_".concat(placeholderId));
        phNodes.forEach(function (phNode) {
            var tlIndex = phNode.getAttribute(constants_2.PH_DATASET.placeholderIndex);
            if (tlIndex) {
                phNode.innerHTML = common_1.PhValuesUtils.getTlPlaceholderValue(phValue, tlIndex);
            }
        });
    });
}
exports.templatedListPlaceholdersValueToDom = templatedListPlaceholdersValueToDom;
var handleFilterOption = function (inputValue, option) {
    if (!option) {
        return false;
    }
    var template = option && option.props && option.props.children;
    if (template && typeof template === 'string') {
        return template.toLocaleUpperCase().indexOf(inputValue.toLocaleUpperCase()) >= 0;
    }
    return false;
};
exports.handleFilterOption = handleFilterOption;
/**
 * Возвращаются данные для отрисовки ПХ/секции в панели редактирования значений ПХ/секций
 * @param placeholder - ПХ
 * @param newValues - значения, которые отличаются от значений в объекте ПХ
 */
function getPhPanelData(placeholder, newValues) {
    var _a, _b, _c;
    if (newValues === void 0) { newValues = null; }
    return {
        attachmentTemplateID: placeholder.attachmentTemplateID,
        desc: placeholder.desc,
        dataFormat: placeholder.dataFormat,
        external_options: placeholder.external_options || placeholder.props.external_options,
        external_params: placeholder.external_params,
        id: placeholder.id,
        isAutonumbering: placeholder.isAutonumbering,
        isRequired: placeholder.isRequired,
        option: newValues === null || newValues === void 0 ? void 0 : newValues.option,
        options: placeholder.options,
        params: placeholder.params,
        settings: placeholder.settings,
        subType: (_a = newValues === null || newValues === void 0 ? void 0 : newValues.subType) !== null && _a !== void 0 ? _a : placeholder.subType,
        type: (_b = newValues === null || newValues === void 0 ? void 0 : newValues.type) !== null && _b !== void 0 ? _b : placeholder.type,
        title: placeholder.title,
        udmsOptions: placeholder.udmsOptions,
        value: (_c = newValues === null || newValues === void 0 ? void 0 : newValues.value) !== null && _c !== void 0 ? _c : placeholder.value,
    };
}
exports.getPhPanelData = getPhPanelData;
var getInvalidPlaceholderValues = function (placeholders, placeholdersValue, templateInfo) {
    var invalidPlaceholders = {};
    var phValuesMap = {};
    var placeholdersMap = {};
    placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.forEach(function (phValue) {
        phValuesMap[phValue.placeholder_id] = phValue;
    });
    placeholders.forEach(function (placeholder) {
        placeholdersMap[placeholder.id] = placeholder;
    });
    var phNodes = document.querySelectorAll('.placeholder');
    phNodes.forEach(function (phNode, elementIndex) {
        var _a, _b, _c, _d;
        if (!common_1.TypeUtils.isHtmlElement(phNode)) {
            return;
        }
        var phId = (_a = phNode.dataset.placeholder) !== null && _a !== void 0 ? _a : '';
        var phValue = phValuesMap[phId];
        // ПХ в ПХ-списке
        if (phNode.dataset.templatedListName) {
            var tlPhId = (_b = phValue === null || phValue === void 0 ? void 0 : phValue.extendedAttributes) === null || _b === void 0 ? void 0 : _b.TemplatedListPlaceholderId;
            if (!phId || !phValue || !tlPhId || phNode.dataset.templatedListRequired !== '1') {
                return;
            }
            if (!(0, exports.isValidTlPlaceholder)(phNode, phValue)) {
                var phTitle = (_c = phNode.dataset.templatedListName) !== null && _c !== void 0 ? _c : '';
                var invalidPlaceholder = invalidPlaceholders[tlPhId];
                if (!(invalidPlaceholder === null || invalidPlaceholder === void 0 ? void 0 : invalidPlaceholder.invalidTlPhs)) {
                    invalidPlaceholders[tlPhId] = {
                        invalidTlPhs: [phTitle],
                        order: elementIndex,
                        title: (_d = placeholdersMap[tlPhId]) === null || _d === void 0 ? void 0 : _d.desc,
                    };
                }
                else {
                    invalidPlaceholder.invalidTlPhs.push(phTitle);
                }
            }
        }
        else {
            // ПХ вне ПХ-списка
            var isValid = true;
            var placeholder = placeholdersMap[phId];
            if (!placeholder) {
                return;
            }
            if (placeholder.type === 'attachment') {
                var isSelectedPDC = !!templateInfo.calculationVersion_id;
                isValid = !(0, placeholderValueValidation_1.isEmptyAttachmentValue)(isSelectedPDC, placeholder, phValuesMap[phId]);
            }
            else {
                isValid = !(0, placeholderValueValidation_1.isEmptyPlaceholderValue)(placeholder, phValuesMap[phId]);
            }
            if (!isValid) {
                invalidPlaceholders[placeholder.id] = {
                    order: elementIndex,
                    title: placeholder.title,
                };
            }
        }
    });
    return invalidPlaceholders;
};
exports.getInvalidPlaceholderValues = getInvalidPlaceholderValues;
var isValidTlPlaceholder = function (phNode, phValue) {
    var _a, _b, _c;
    var phIndex = Number(phNode.dataset.placeholderIndex) || 0;
    var valueContent = phValue === null || phValue === void 0 ? void 0 : phValue.valueContent;
    var isValid = true;
    // ПХ с optionId
    if (common_1.PhValuesUtils.isTLKeyValue(valueContent)) {
        var currenItem_1 = (_a = valueContent.items[phIndex]) !== null && _a !== void 0 ? _a : {};
        // проверяются справочники
        if (!phNode.dataset.placeholderOptions && !currenItem_1.value) {
            isValid = false;
        }
        // проверяются radio/cascader
        if (phNode.dataset.placeholderOptions) {
            var options = (_b = (0, jsonParse_1.jsonParse)(phNode.dataset.placeholderOptions)) !== null && _b !== void 0 ? _b : [];
            var option = options === null || options === void 0 ? void 0 : options.find(function (option) { return option.id === currenItem_1.key; });
            // ввод пользователем
            if (option === null || option === void 0 ? void 0 : option.userInput) {
                isValid = !!currenItem_1.value;
            }
            else if (!(option === null || option === void 0 ? void 0 : option.emptyValue)) {
                // значение выбранное пользователем
                isValid = !!currenItem_1.value;
            }
        }
    }
    else {
        // текстовые ПХ
        if (!((_c = phValue === null || phValue === void 0 ? void 0 : phValue.values) === null || _c === void 0 ? void 0 : _c[phIndex])) {
            isValid = false;
        }
    }
    return isValid;
};
exports.isValidTlPlaceholder = isValidTlPlaceholder;
