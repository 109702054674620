"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhUtils = void 0;
var TypeUtils_1 = require("./TypeUtils");
var PhUtils = /** @class */ (function () {
    function PhUtils() {
    }
    PhUtils.createNewPhName = function (node) {
        if (!TypeUtils_1.TypeUtils.isElement(node) || !node.textContent) {
            return '';
        }
        var result = node.textContent.match(/^(\d+\. )(.*)/);
        // если нет result, ПХ без нумерации
        return result ? result[2] : node.textContent;
    };
    PhUtils.createPhTextContent = function (ph) {
        return "".concat(ph.order, ". ").concat(ph.title);
    };
    return PhUtils;
}());
exports.PhUtils = PhUtils;
