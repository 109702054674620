"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOUNDARY_CLASSES = exports.BOUNDARY_CLASS_NAMES = void 0;
exports.BOUNDARY_CLASS_NAMES = {
    content: 'boundary-content',
    left: 'boundary_left',
    right: 'boundary_right',
    placeholder: 'boundary',
};
exports.BOUNDARY_CLASSES = {
    content: ".".concat(exports.BOUNDARY_CLASS_NAMES.content),
    left: ".".concat(exports.BOUNDARY_CLASS_NAMES.left),
    boundary: ".".concat(exports.BOUNDARY_CLASS_NAMES.placeholder),
    right: ".".concat(exports.BOUNDARY_CLASS_NAMES.right),
};
