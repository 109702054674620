"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.placeholderValueDisabled = exports.sortOrderUnfilledPhs = exports.addTlUnfilledPhs = exports.isInvalidOptionValue = exports.isEmptyPlaceholderValue = exports.isTlPlaceholder = exports.isEmptyAttachmentValue = exports.isEmptyTemplatedListValue = exports.isEmptyTextValue = exports.isEmptyValue = void 0;
var constants_1 = require("../../shared/utils/constants");
var common_1 = require("../../shared/utils/common");
var jsonParse_1 = require("../../shared/utils/jsonParse");
/**
 * Проверяется обязательность заполнения ПХ
 * @param placeholder
 */
var isRequired = function (placeholder) {
    return (placeholder === null || placeholder === void 0 ? void 0 : placeholder.isRequired) && (placeholder === null || placeholder === void 0 ? void 0 : placeholder.type) !== 'link';
};
/**
 * Проверяются ПХ без optionId на обязательное заполнение
 * @param placeholder
 * @param placeholderValue
 */
var isEmptyValue = function (placeholder, placeholderValue) {
    if (!(placeholder instanceof Object) || !isRequired(placeholder)) {
        return false;
    }
    if (!placeholderValue) {
        return true;
    }
    return !(placeholderValue === null || placeholderValue === void 0 ? void 0 : placeholderValue.disabled) && (0, exports.isEmptyTextValue)(placeholderValue.value);
};
exports.isEmptyValue = isEmptyValue;
/**
 * Проверяется обязательное заполнение текстового значения
 * @param value
 */
var isEmptyTextValue = function (value) { return !(value === null || value === void 0 ? void 0 : value.replace(/\s/g, '')); };
exports.isEmptyTextValue = isEmptyTextValue;
var isEmptyTemplatedListValue = function (placeholder, placeholdersValue) {
    var _a, _b;
    var phTlId = placeholder.id;
    var tlPhValuesMap = {};
    placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.forEach(function (phValue) {
        var _a;
        if (((_a = phValue.extendedAttributes) === null || _a === void 0 ? void 0 : _a.TemplatedListPlaceholderId) === phTlId) {
            tlPhValuesMap[phValue.placeholder_id] = phValue;
        }
    });
    var phNodes = document.querySelectorAll('[data-templated-list-required="1"]');
    var isValid = true;
    var _loop_1 = function (i) {
        if (!isValid) {
            return "break";
        }
        var phNode = phNodes[i];
        if (!common_1.TypeUtils.isHtmlElement(phNode) || !phNode.dataset.placeholder) {
            return "continue";
        }
        var phId = phNode.dataset.placeholder;
        var phIndex = Number(phNode.dataset.placeholderIndex) || 0;
        var phValue = tlPhValuesMap[phId];
        var valueContent = phValue === null || phValue === void 0 ? void 0 : phValue.valueContent;
        if (common_1.PhValuesUtils.isPhWithOptionId(phNode.dataset.placeholderField)) {
            if (!common_1.PhValuesUtils.isTLKeyValue(valueContent)) {
                isValid = false;
                return "break";
            }
            var currenItem_1 = valueContent.items[phIndex];
            if (!currenItem_1 || (!phNode.dataset.placeholderOptions && !currenItem_1.value)) {
                isValid = false;
                return "break";
            }
            var options = (_a = (0, jsonParse_1.jsonParse)(phNode.dataset.placeholderOptions)) !== null && _a !== void 0 ? _a : [];
            var option = options === null || options === void 0 ? void 0 : options.find(function (option) { return option.id === currenItem_1.key; });
            if (!(option === null || option === void 0 ? void 0 : option.emptyValue) && !currenItem_1.value) {
                isValid = false;
                return "break";
            }
        }
        else {
            // текстовые ПХ
            if (!((_b = phValue === null || phValue === void 0 ? void 0 : phValue.values) === null || _b === void 0 ? void 0 : _b[phIndex])) {
                isValid = false;
                return "break";
            }
        }
    };
    for (var i = 0; i < phNodes.length; i++) {
        var state_1 = _loop_1(i);
        if (state_1 === "break")
            break;
    }
    return !isValid;
};
exports.isEmptyTemplatedListValue = isEmptyTemplatedListValue;
var isEmptyAttachmentValue = function (isSelectedPDC, placeholder, placeholderValue) {
    if (!isRequired(placeholder)) {
        return false;
    }
    if (!placeholder.dataFormat)
        return false;
    var isAttachmentFixed = ['fixedPDC', 'fixedPlan'].includes(placeholder.dataFormat);
    if (isAttachmentFixed && isSelectedPDC) {
        return false;
    }
    if (!placeholderValue) {
        return true;
    }
    if (placeholder.dataFormat === 'fromTemplate' &&
        placeholderValue.createdDocumentID &&
        placeholderValue.createdDocumentID.file_key) {
        return false;
    }
    return (0, exports.isEmptyValue)(placeholder, placeholderValue);
};
exports.isEmptyAttachmentValue = isEmptyAttachmentValue;
/**
 * Проверяется тип передаваемого значения на соответствие ITlDocumentPlaceholder
 * @param placeholder
 */
var isTlPlaceholder = function (placeholder) {
    return placeholder instanceof Object && 'TemplatedListPlaceholderId' in placeholder;
};
exports.isTlPlaceholder = isTlPlaceholder;
/**
 * Проверяются ПХ на обязательное заполнение
 * @param placeholder - может быть PlaceHolder | IDocumentPlaceholder | ITlDocumentPlaceholder
 * @param placeholderValue - значение ПХ
 */
var isEmptyPlaceholderValue = function (placeholder, placeholderValue) {
    if (!isRequired(placeholder)) {
        return false;
    }
    if (!placeholderValue) {
        return true;
    }
    if (placeholderValue === null || placeholderValue === void 0 ? void 0 : placeholderValue.disabled) {
        return false;
    }
    // ПХ в ПХ-списке
    if ((0, exports.isTlPlaceholder)(placeholder)) {
        var value = common_1.PhValuesUtils.getTlPlaceholderValue(placeholderValue, placeholder.placeholderIndex);
        // ПХ с optionId
        if (common_1.PhValuesUtils.isSelectPh(placeholder.type)) {
            var optionId = common_1.PhValuesUtils.getOptionId(placeholderValue, placeholder.placeholderIndex);
            return (0, exports.isInvalidOptionValue)(placeholder.options, optionId, value);
        }
        // текстовый ПХ
        return (0, exports.isEmptyTextValue)(value);
    }
    else {
        // ПХ с optionId
        if (common_1.PhValuesUtils.isSelectPh(placeholder.type)) {
            var optionId = common_1.PhValuesUtils.getOptionId(placeholderValue);
            var value = common_1.PhValuesUtils.getOptionValue(placeholderValue);
            return (0, exports.isInvalidOptionValue)(placeholder.options, optionId, value);
        }
        // текстовый ПХ
        return (0, exports.isEmptyTextValue)(common_1.PhValuesUtils.getPlaceholderValue(placeholderValue));
    }
};
exports.isEmptyPlaceholderValue = isEmptyPlaceholderValue;
/**
 * Валидация динамических ПХ
 * @param options
 * @param optionId
 * @param value
 */
var isInvalidOptionValue = function (options, optionId, value) {
    if (!optionId) {
        return true;
    }
    var option = options.find(function (x) { return x.id === optionId; });
    if (option === null || option === void 0 ? void 0 : option.emptyValue) {
        return false;
    }
    if (option === null || option === void 0 ? void 0 : option.userInput) {
        return !value || value === constants_1.defaultInputValues.userInput;
    }
    return false;
};
exports.isInvalidOptionValue = isInvalidOptionValue;
var addTlUnfilledPhs = function (tlPlaceholders, phValues) {
    var _a;
    if (phValues === void 0) { phValues = []; }
    return (_a = tlPlaceholders === null || tlPlaceholders === void 0 ? void 0 : tlPlaceholders.filter(function (ph) { return (0, exports.isEmptyTemplatedListValue)(ph, phValues); })) !== null && _a !== void 0 ? _a : [];
};
exports.addTlUnfilledPhs = addTlUnfilledPhs;
var findAllNestedBlocks = function (blocks, sections) {
    var allBlocks = [];
    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
        if ((block === null || block === void 0 ? void 0 : block.type) === 'section') {
            var blockId_1 = block === null || block === void 0 ? void 0 : block.id;
            var searchFromSections = sections === null || sections === void 0 ? void 0 : sections.filter(function (section) { return (section === null || section === void 0 ? void 0 : section.sectionId) === blockId_1; });
            if (searchFromSections === null || searchFromSections === void 0 ? void 0 : searchFromSections.length) {
                allBlocks.push.apply(allBlocks, searchFromSections);
            }
        }
        else {
            allBlocks.push(block);
        }
    });
    // проверка если присутствуют вложенные секции
    if ((allBlocks === null || allBlocks === void 0 ? void 0 : allBlocks.length) && (allBlocks === null || allBlocks === void 0 ? void 0 : allBlocks.some(function (block) { return (block === null || block === void 0 ? void 0 : block.type) === 'section'; }))) {
        return findAllNestedBlocks(allBlocks, sections);
    }
    else {
        return allBlocks;
    }
};
var sortOrderUnfilledPhs = function (blocks, result, sections) {
    if (blocks === void 0) { blocks = []; }
    if (result === void 0) { result = []; }
    if (sections === void 0) { sections = []; }
    var sortedResult = [];
    var foundBlocks = findAllNestedBlocks(blocks, sections);
    // сортируем все незаполненные пх по порядку блоков в полотне документа
    var sortBlocksWithPhs = foundBlocks === null || foundBlocks === void 0 ? void 0 : foundBlocks.filter(function (block) { return result === null || result === void 0 ? void 0 : result.some(function (res) { return res.elementId === block.id; }); });
    sortBlocksWithPhs === null || sortBlocksWithPhs === void 0 ? void 0 : sortBlocksWithPhs.forEach(function (sortRes) {
        var findPhs = result === null || result === void 0 ? void 0 : result.filter(function (ph) { return ph.elementId === sortRes.id; });
        findPhs === null || findPhs === void 0 ? void 0 : findPhs.sort(function (a, b) { return a.order - b.order; });
        sortedResult.push.apply(sortedResult, findPhs);
    });
    return sortedResult;
};
exports.sortOrderUnfilledPhs = sortOrderUnfilledPhs;
var placeholderValueDisabled = function (placeholder, placeholdersValue) {
    var placeholderId = placeholder.id;
    var currentPlaceholderValue = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.find(function (placeholderValue) { return placeholderValue.placeholder_id === placeholderId; });
    var disabled = currentPlaceholderValue === null || currentPlaceholderValue === void 0 ? void 0 : currentPlaceholderValue.disabled;
    return disabled;
};
exports.placeholderValueDisabled = placeholderValueDisabled;
