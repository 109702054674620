"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUtils = void 0;
var CheckUtils_1 = require("./CheckUtils");
var TypeUtils_1 = require("./TypeUtils");
/**
 * Утилиты получения данных из DOM
 */
var GetUtils = /** @class */ (function () {
    function GetUtils() {
    }
    /**
     * Поиск родительского узла
     * @param element - HTMLElement
     * @param func - функция, по которой определяется родитель
     * @param [wrappableBlock=null] - HTMLElement, выше которого искать узел не нужно
     */
    GetUtils.getParentElement = function (element, func, wrappableBlock) {
        if (TypeUtils_1.TypeUtils.isElement(element) && func(element)) {
            return element;
        }
        if (wrappableBlock && wrappableBlock === element) {
            return null;
        }
        return TypeUtils_1.TypeUtils.isNode(element) ? GetUtils.getParentElement(element.parentNode, func, wrappableBlock) : null;
    };
    /**
     * Поиск выделенных пользователем узлов
     */
    GetUtils.getSelectedNodeList = function () {
        var _a;
        var sel = window.getSelection();
        if (!sel || sel.isCollapsed) {
            return [];
        }
        return Array.from((_a = sel.getRangeAt(0).cloneContents().childNodes) !== null && _a !== void 0 ? _a : []);
    };
    GetUtils.getElementByTagAndAttribute = function (rootElement, tag, attrName, attrValue) {
        return rootElement.querySelector("".concat(tag, "[").concat(attrName, "=\"").concat(attrValue, "\"]"));
    };
    /**
     * anchorNode и baseNode эквивалентны, baseNode - устаревшее свойство, но используется в проекте давно, поэтому метод называется так
     * @return возвращает узел, в котором начинается выделение текста пользователем
     */
    GetUtils.getBaseNode = function () {
        var _a, _b;
        return (_b = (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.anchorNode) !== null && _b !== void 0 ? _b : null;
    };
    /**
     * Поиск id родителя, являющегося блоком-контейнером .block-wrapper
     * @param value - id узла или узел, по которому ищем id родителя
     */
    GetUtils.getBlockNodeId = function (value) {
        var _a, _b;
        var node = null;
        if (typeof value === 'string') {
            node = document.getElementById(value);
        }
        if (TypeUtils_1.TypeUtils.isElement(value)) {
            node = value;
        }
        return (_b = (_a = GetUtils.getParentElement(node, CheckUtils_1.CheckUtils.isWrapBlock)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '';
    };
    return GetUtils;
}());
exports.GetUtils = GetUtils;
