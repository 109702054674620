"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Directory = exports.templatedListConstants = exports.moneyFormat = exports.curtOptions = exports.themes = exports.defaultFileExtension = exports.placeholdersActions = exports.PLACEHOLDER_OPTION_TYPES = exports.PLACEHOLDER_ATTRIBUTES = exports.NOT_REQUIRED_ATTACHMENTS = exports.optionTypes = exports.defaultInputValues = exports.types = void 0;
exports.types = {
    create: 'create',
    document: 'document',
    template: 'template',
    libraryBlock: 'libraryBlock',
};
exports.defaultInputValues = {
    any: 'Любое другое значение',
    userInput: 'Ввод пользователем',
    emptyValue: 'Пустое значение',
};
exports.optionTypes = {
    default: 'default',
    userInput: 'userInput',
    emptyValue: 'emptyValue',
};
exports.NOT_REQUIRED_ATTACHMENTS = {
    fixedPDC: true,
    fixedPlan: true,
    text: true,
};
exports.PLACEHOLDER_ATTRIBUTES = {
    text: ['dataFormat'],
    formula: ['formula_id', 'parent_id'],
    link: ['parent_id'],
    UDMS: ['fromSystem', 'dataFormat'],
    attachment: ['dataFormat'],
    radio: ['options'],
    cascader: ['options'],
    fromTemplate: ['fromPlaceholder_id'],
    templatedList: ['dataFormat'],
};
exports.PLACEHOLDER_OPTION_TYPES = ['UDMS', 'directory'];
exports.placeholdersActions = {
    copy: 'copy',
};
exports.defaultFileExtension = 'pdf';
exports.themes = ['light', 'dark', 'color'];
exports.curtOptions = ['statements', 'petitions', 'revocationStatementOfClaim', 'statementOfClaim'];
exports.moneyFormat = ['money'];
exports.templatedListConstants = __spreadArray(['revokePowerOfAttorney'], exports.curtOptions, true);
var Directory;
(function (Directory) {
    Directory["legacyCountry"] = "legacyCountry";
    Directory["individualDocumentType"] = "individualDocument";
})(Directory = exports.Directory || (exports.Directory = {}));
