"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomUtils = void 0;
var constants_1 = require("../../constants");
var TypeUtils_1 = require("./TypeUtils");
var AttrUtils_1 = require("./AttrUtils");
var DomUtils = /** @class */ (function () {
    function DomUtils() {
    }
    DomUtils.hasBoundary = function (parent) {
        return TypeUtils_1.TypeUtils.isElement(parent) && !!parent.querySelector("[".concat(constants_1.PH_ATTRS.boundaryId, "]"));
    };
    DomUtils.selectBoundaryContents = function (parent) {
        return parent.querySelectorAll(constants_1.BOUNDARY_CLASSES.content);
    };
    DomUtils.selectBoundaries = function (parent) {
        return parent.querySelectorAll("[".concat(constants_1.PH_ATTRS.boundaryId, "]"));
    };
    DomUtils.selectPlaceholders = function (parent) {
        return parent.querySelectorAll(constants_1.CLASSES.placeholder);
    };
    DomUtils.selectPlaceholder = function (id) {
        return document.getElementById(AttrUtils_1.AttrUtils.createPhId(id));
    };
    DomUtils.selectElementById = function (id) { return document.getElementById(id); };
    DomUtils.selectBoundaryContentsById = function (id) {
        return document.querySelectorAll("".concat(constants_1.BOUNDARY_CLASSES.content, "[").concat(constants_1.PH_ATTRS.boundaryId, "=\"").concat(id, "\"]"));
    };
    DomUtils.selectCopiedBoundaryById = function (id) {
        return document.querySelectorAll("[".concat(constants_1.PH_ATTRS.boundaryCopiedId).concat(id ? "=\"".concat(id, "\"") : '', "]"));
    };
    return DomUtils;
}());
exports.DomUtils = DomUtils;
