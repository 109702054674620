"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NODE_STYLE = exports.NodeStyles = exports.TEXT_ALIGN_STYLES = exports.TextAlign = exports.STYLE_TAG_NAMES = exports.CREATE_PH_CLASSES = exports.ATTR_NAMES = exports.CLASSES = exports.CLASS_NAMES = exports.PH_ATTRS = exports.PH_DATASET = void 0;
/**
 * Объект аттрибутов для записи данных ПХ.
 * В первой версии списков были текстовые ПХ и данные из системы, в разметке отличались аттрибутом type, в который записывался dataFormat. Позже появились справочники и добавился атрибут placeholderField, в уже созданных списках у текстовых ПХ его нет. Доступные для списков типы ПХ в последней версии: cascader, directory, radio, text, UDMS
 * @property placeholderField - type ПХ, не заполнен в старых списках
 * @property placeholderIndex - индекс ПХ в одномерных/многомерных списках
 * @property placeholderOptions - варианты динамических ПХ
 * @property placeholderType - не type ПХ, а dataFormat
 * @property templatedListPh - должен быть равен "1" в ПХ с типом не UDMS, в старых списках может отсутствовать для текстовых ПХ (используется для вывода в форме редактирования)
 * @property templatedListName - название ПХ-списка
 * @property templatedListRequired - обязательность заполнения ПХ
 */
exports.PH_DATASET = {
    hideOption: 'data-hide-option',
    placeholderField: 'data-placeholder-field',
    placeholderIndex: 'data-placeholder-index',
    placeholderOptions: 'data-placeholder-options',
    placeholderType: 'data-placeholder-type',
    templatedListPh: 'data-templated-list-ph',
    templatedListName: 'data-templated-list-name',
    templatedListRequired: 'data-templated-list-required',
};
exports.PH_ATTRS = {
    id: 'data-placeholder',
    boundaryId: 'data-boundary',
    boundaryCopiedId: 'data-copied-boundary-placeholder-id',
    copiedId: 'data-copied-placeholder-id',
};
exports.CLASS_NAMES = {
    // ПХ
    aggregation: 'aggregation',
    editing: 'editing',
    filling: 'filling',
    placeholder: 'placeholder',
    creating: 'editing_warning',
    warning: 'warning',
    // разное
    inlineText: 'inline-text',
    listWrapper: 'nested',
    textParagraph: 'text-paragraph',
    textStyle: 'text-style',
    // blocks
    block: 'block',
    libBlock: 'library-block',
    authorityBlock: 'block--authority',
    listBlock: 'block--list',
    imageBlock: 'block--image',
    textBlock: 'block--text',
    header2Block: 'block--header2',
    textBlockLeft: 'block--text-left',
    textBlockRight: 'block--text-right',
    tableBlock: 'block--table',
    tableCellBlock: 'block--table-cell',
};
exports.CLASSES = {
    // ПХ
    aggregation: ".".concat(exports.CLASS_NAMES.aggregation),
    editing: ".".concat(exports.CLASS_NAMES.editing),
    filling: ".".concat(exports.CLASS_NAMES.filling),
    placeholder: ".".concat(exports.CLASS_NAMES.placeholder),
    selected: ".".concat(exports.CLASS_NAMES.creating),
    warning: ".".concat(exports.CLASS_NAMES.warning),
    // разное
    inlineText: ".".concat(exports.CLASS_NAMES.inlineText),
    listWrapper: ".".concat(exports.CLASS_NAMES.listWrapper),
    textParagraph: ".".concat(exports.CLASS_NAMES.textParagraph),
    textStyle: ".".concat(exports.CLASS_NAMES.textStyle),
    // blocks
    block: ".".concat(exports.CLASS_NAMES.block),
    libBlock: ".".concat(exports.CLASS_NAMES.listBlock),
    listBlock: ".".concat(exports.CLASS_NAMES.listBlock),
    textBlock: ".".concat(exports.CLASS_NAMES.textBlock),
    tableBlock: ".".concat(exports.CLASS_NAMES.tableBlock),
};
exports.ATTR_NAMES = {
    paragraph: 'data-is-paragraph',
};
exports.CREATE_PH_CLASSES = [
    exports.CLASS_NAMES.placeholder,
    exports.CLASS_NAMES.aggregation,
    exports.CLASS_NAMES.editing,
    exports.CLASS_NAMES.creating,
];
exports.STYLE_TAG_NAMES = ['B', 'I', 'U'];
var TextAlign;
(function (TextAlign) {
    TextAlign["Justify"] = "justify";
    TextAlign["Center"] = "center";
    TextAlign["Left"] = "left";
    TextAlign["Right"] = "right";
})(TextAlign = exports.TextAlign || (exports.TextAlign = {}));
exports.TEXT_ALIGN_STYLES = {
    txt_left: {
        cn: 'justify-left',
        position: 'position-left',
        value: 'left',
    },
    txt_center: {
        cn: 'justify-center',
        position: 'position-center',
        value: 'center',
    },
    txt_right: {
        cn: 'justify-right',
        position: 'position-right',
        value: 'right',
    },
    txt_justify: {
        cn: 'justify',
        position: 'position',
        value: '',
    },
    // remove
    JustifyLeft: {
        cn: 'justify-left',
        position: 'position-left',
        value: 'left',
    },
    JustifyCenter: {
        cn: 'justify-center',
        position: 'position-center',
        value: 'center',
    },
    JustifyRight: {
        cn: 'justify-right',
        position: 'position-right',
        value: 'right',
    },
    Justify: {
        cn: 'justify',
        position: 'position',
        value: '',
    },
};
var NodeStyles;
(function (NodeStyles) {
    NodeStyles["Bold"] = "bold";
    NodeStyles["Italic"] = "italic";
    NodeStyles["Underline"] = "underline";
})(NodeStyles = exports.NodeStyles || (exports.NodeStyles = {}));
exports.NODE_STYLE = (_a = {},
    _a[NodeStyles.Bold] = {
        class: 'bold',
        id: NodeStyles.Bold,
        tagName: 'B',
    },
    _a[NodeStyles.Italic] = {
        class: 'italic',
        id: NodeStyles.Italic,
        tagName: 'I',
    },
    _a[NodeStyles.Underline] = {
        class: 'underline',
        id: NodeStyles.Underline,
        tagName: 'U',
    },
    _a);
