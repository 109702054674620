"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessUtils = void 0;
var EntityUtils_1 = require("./EntityUtils");
var cantBeRelated = ['link', 'attachment', 'formula', 'customFormula', 'fromTemplate'];
var cantBeHidden = ['attachment', 'section'];
var AccessUtils = /** @class */ (function () {
    function AccessUtils() {
    }
    /**
     * Проверка можно ли связать ПХ/секцию с другим ПХ/секцией
     * @param templateType - тип шаблона
     * @param type - тип ПХ
     * @param subType - подтип ПХ
     */
    AccessUtils.mayBeRelated = function (templateType, type, subType) {
        return (!!type && subType !== 'boundary' && !cantBeRelated.includes(type) && !EntityUtils_1.EntityUtils.isTemplatedList(templateType));
    };
    /**
     * Проверка можно ли скрыть ПХ
     * @param templateType - тип шаблона
     * @param type - тип ПХ
     * @param subType - подтип ПХ
     */
    AccessUtils.mayBeHidden = function (templateType, type, subType) {
        return (!!type &&
            subType !== 'boundary' &&
            !cantBeHidden.includes(type) &&
            !EntityUtils_1.EntityUtils.isCourtDocumentEntity(templateType));
    };
    return AccessUtils;
}());
exports.AccessUtils = AccessUtils;
