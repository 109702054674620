"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
// TODO: [Mouseee] Проверить правильно ли актуализироан файл МР-кой
var react_1 = require("react");
var placeholderValidationUtils_1 = require("../../utils/placeholderValidationUtils");
var templatedListConstants_1 = require("../../utils/templatedListConstants");
var constants_1 = require("../../constants");
var common_1 = require("../../utils/common");
var placeholderUtils_1 = require("../TemplateRender/utils/placeholderUtils");
var boundaryUtils_1 = require("../TemplateRender/utils/boundaryUtils");
var PlaceHolder = /** @class */ (function (_super) {
    __extends(PlaceHolder, _super);
    function PlaceHolder(props) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        _this = _super.call(this, props) || this;
        _this.required = false;
        _this.onClick = function () {
            var _a, _b;
            (_b = (_a = _this.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a, _this);
            _this.setEditing(true);
        };
        _this.setInvalid = function (flag) {
            _this.invalidFlags |= flag;
            var isValid = _this.invalidFlags === placeholderValidationUtils_1.InvalidEnum.None;
            _this.setWarning(!isValid);
        };
        _this.resetInvalid = function (flag) {
            _this.invalidFlags &= ~flag;
            var isValid = _this.invalidFlags === placeholderValidationUtils_1.InvalidEnum.None;
            _this.setWarning(!isValid);
        };
        _this.setAction = function (action) {
            if (action === void 0) { action = ''; }
            _this.action = action;
        };
        _this.getNodes = function () {
            var _a;
            var nodes = [];
            if (_this.subType === 'boundary') {
                nodes = Array.from((_a = _this.findBoundaryContentElements()) !== null && _a !== void 0 ? _a : []);
            }
            else {
                var element = _this.findDOM();
                if (element) {
                    nodes.push(element);
                }
            }
            return nodes;
        };
        _this.setWarning = function (isWarning) {
            if (isWarning === void 0) { isWarning = false; }
            _this.editClassList([constants_1.CLASS_NAMES.warning], isWarning);
        };
        _this.setEditing = function (isEditing) {
            if (isEditing === void 0) { isEditing = false; }
            var classes = [constants_1.CLASS_NAMES.editing];
            if (!isEditing) {
                classes.push(constants_1.CLASS_NAMES.creating);
            }
            _this.is_editing = isEditing;
            _this.editClassList(classes, isEditing);
        };
        _this.editClassList = function (classes, add) {
            var nodes = _this.getNodes();
            nodes.forEach(function (node) {
                var _a, _b;
                if (add) {
                    (_a = node.classList).add.apply(_a, classes);
                }
                else {
                    (_b = node.classList).remove.apply(_b, classes);
                }
            });
        };
        _this.setTitle = function (title, order) {
            if (title === void 0) { title = ''; }
            _this.title = title;
            if (order) {
                _this.order = order;
            }
            _this.updateDom(title, order);
        };
        _this.getHtml = function () {
            var _a = _this, id = _a.id, title = _a.title;
            var tmp_place = '{{id}}';
            /**
             * hack for insert placeholder
             * if we have a single placeholder, after Justify* in execCommand
             * it will be lost own attributes
             * Need insert smth after placeholder for right work
             * for example it can be &zwnj; - zero width non-joiner
             */
            return tmp_place.replace(tmp_place, "<span id=\"ph_".concat(id, "\" contenteditable=\"false\" data-placeholder=\"").concat(id, "\" onclick=\"window.textEditor.onPlaceholderClick(this)\" class=\"").concat(constants_1.CREATE_PH_CLASSES.join(' '), "\">").concat(title, "</span>"));
        };
        _this.getNode = function (isEditing) {
            if (isEditing === void 0) { isEditing = true; }
            if (_this.subType === 'boundary') {
                return (0, boundaryUtils_1.createBoundaryLeftElement)(_this.id);
            }
            var phNode = (0, placeholderUtils_1.createPlaceholder)(_this.id);
            phNode.innerText = _this.title;
            if (isEditing) {
                phNode.classList.add(constants_1.CLASS_NAMES.editing, constants_1.CLASS_NAMES.creating);
            }
            return phNode;
        };
        _this.createRightBoundary = function () {
            return (0, boundaryUtils_1.createBoundaryRightElement)(_this.id);
        };
        _this.updateDom = function (title, order) {
            var DOM = _this.findDOM();
            var phTitle = order && _this.type !== 'attachment' && _this.subType !== templatedListConstants_1.options.templatedList.placeholder
                ? "".concat(order, ". ").concat(title)
                : title;
            if (common_1.TypeUtils.isHtmlElement(DOM)) {
                DOM.innerText = phTitle || '';
            }
        };
        // для изменения id при разрыве связи с ББ
        _this.setId = function (id) {
            _this.id = id;
        };
        _this.refresh = function (newTitle, order) {
            if (newTitle === void 0) { newTitle = _this.title; }
            if (order === void 0) { order = _this.order; }
            if (_this.type !== 'attachment')
                _this.setTitle(newTitle);
            _this.setTitle(newTitle, order);
        };
        _this.findDOM = function () { return common_1.DomUtils.selectPlaceholder(_this.id); };
        _this.findRightBoundaryDOM = function () { return common_1.DomUtils.selectElementById(common_1.AttrUtils.createBoundaryRightPhId(_this.id)); };
        _this.findBoundaryContentElements = function () { return common_1.DomUtils.selectBoundaryContentsById(_this.id); };
        _this.toggleHidden = function (value) {
            var DOM = _this.findDOM();
            var isHidden = value !== null && value !== void 0 ? value : !_this.isHidden;
            _this.isHidden = isHidden;
            if (isHidden) {
                DOM === null || DOM === void 0 ? void 0 : DOM.setAttribute(constants_1.PH_DATASET.hideOption, _this.id);
            }
            else {
                DOM === null || DOM === void 0 ? void 0 : DOM.removeAttribute(constants_1.PH_DATASET.hideOption);
            }
        };
        _this.action = (_a = props.action) !== null && _a !== void 0 ? _a : '';
        _this.attachmentDocumentID = (_b = props.attachmentDocumentID) !== null && _b !== void 0 ? _b : null;
        _this.attachmentTemplateID = (_c = props.attachmentTemplateID) !== null && _c !== void 0 ? _c : null;
        _this.customFormulaData = (_d = props.customFormulaData) !== null && _d !== void 0 ? _d : null;
        _this.dataFormat = (_e = props.dataFormat) !== null && _e !== void 0 ? _e : '';
        _this.dataValue = (_f = props.dataValue) !== null && _f !== void 0 ? _f : '';
        _this.desc = (_g = props.desc) !== null && _g !== void 0 ? _g : '';
        _this.external_params = (_h = props.external_params) !== null && _h !== void 0 ? _h : null;
        _this.external_options = (_j = props.external_options) !== null && _j !== void 0 ? _j : [];
        _this.format = (_k = props.format) !== null && _k !== void 0 ? _k : null;
        _this.formula_id = (_l = props.formula_id) !== null && _l !== void 0 ? _l : null;
        _this.fromPlaceholder_id = (_m = props.fromPlaceholder_id) !== null && _m !== void 0 ? _m : null;
        _this.fromSystem = (_o = props.fromSystem) !== null && _o !== void 0 ? _o : '';
        _this.id = props.id;
        _this.initial_title = (_p = props.initial_title) !== null && _p !== void 0 ? _p : '';
        _this.isAutonumbering = (_q = props.isAutonumbering) !== null && _q !== void 0 ? _q : false;
        _this.isHidden = props.isHidden;
        _this.isLib = (_r = props.isLib) !== null && _r !== void 0 ? _r : false;
        _this.isReceived = (_s = props.isReceived) !== null && _s !== void 0 ? _s : false;
        _this.isRequired = (_t = props.isRequired) !== null && _t !== void 0 ? _t : false;
        _this.isSent = (_u = props.isSent) !== null && _u !== void 0 ? _u : false;
        _this.libraryPlaceholder_id = (_v = props.libraryPlaceholder_id) !== null && _v !== void 0 ? _v : '';
        _this.name = '';
        _this.options = (_w = props.options) !== null && _w !== void 0 ? _w : [];
        _this.order = (_x = props.order) !== null && _x !== void 0 ? _x : 0;
        _this.settings = (_y = props.settings) !== null && _y !== void 0 ? _y : {};
        _this.subType = (_z = props.subType) !== null && _z !== void 0 ? _z : '';
        _this.target = (_0 = props.target) !== null && _0 !== void 0 ? _0 : [];
        _this.type = props.type;
        _this.udmsOptions = (_1 = props.udmsOptions) !== null && _1 !== void 0 ? _1 : {};
        _this.canBeEditedInPreview = (_2 = props.canBeEditedInPreview) !== null && _2 !== void 0 ? _2 : false;
        _this.elementId = (_3 = props.elementId) !== null && _3 !== void 0 ? _3 : '';
        _this.invalidFlags = placeholderValidationUtils_1.InvalidEnum.None;
        _this.is_editing = true;
        _this.has_relations = (_4 = props.has_relations) !== null && _4 !== void 0 ? _4 : false;
        _this.parent = null;
        _this.params = props.params;
        _this.parent_id = (_5 = props.parent_id) !== null && _5 !== void 0 ? _5 : undefined;
        _this.title = props.title;
        return _this;
    }
    return PlaceHolder;
}(react_1.Component));
exports.default = PlaceHolder;
