"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUtils = void 0;
var constants_1 = require("../../constants");
var TypeUtils_1 = require("./TypeUtils");
var CheckUtils = /** @class */ (function () {
    function CheckUtils() {
    }
    CheckUtils.getElementAttr = function (node, attr) {
        var _a;
        return TypeUtils_1.TypeUtils.isElement(node) ? (_a = node.getAttribute(attr)) !== null && _a !== void 0 ? _a : '' : '';
    };
    // attachment, block, list, table-cell
    CheckUtils.getWrappableAttr = function (node) { return CheckUtils.getElementAttr(node, 'wrappable'); };
    CheckUtils.hasId = function (node) { return !!CheckUtils.getElementAttr(node, 'id'); };
    CheckUtils.hasClassName = function (node, className) { var _a, _b; return TypeUtils_1.TypeUtils.isElement(node) ? (_b = (_a = node.classList) === null || _a === void 0 ? void 0 : _a.contains(className)) !== null && _b !== void 0 ? _b : false : false; };
    CheckUtils.isTextStyle = function (node) { return CheckUtils.hasClassName(node, 'text-style'); };
    // текст и текст ПП
    CheckUtils.isInlineText = function (node) { return CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.inlineText); };
    CheckUtils.isEmptyBlock = function (node) {
        var _a;
        return TypeUtils_1.TypeUtils.isElement(node) &&
            node.childNodes.length === 1 &&
            ((_a = node.firstChild) === null || _a === void 0 ? void 0 : _a.childNodes.length) === 1 &&
            CheckUtils.isEmptyText(node.firstChild.firstChild);
    };
    CheckUtils.isEmptyParagraph = function (node) {
        return TypeUtils_1.TypeUtils.isElement(node) && node.childNodes.length === 1 && CheckUtils.isEmptyText(node.firstChild);
    };
    CheckUtils.isEmptyText = function (node) {
        return TypeUtils_1.TypeUtils.isElement(node) && !node.innerHTML.replaceAll(constants_1.U_ZWNJ, '');
    };
    /* class placeholder у ПХ, boundary_left, boundary_right, attachment */
    CheckUtils.hasPlaceholderClass = function (node) { return CheckUtils.hasClassName(node, 'placeholder'); };
    CheckUtils.isPlaceholder = function (node) {
        return CheckUtils.hasPlaceholderClass(node) && !CheckUtils.isBoundary(node);
    };
    /* boundary_left, boundary-content, boundary_right */
    CheckUtils.isBoundary = function (node) { return !!CheckUtils.getElementAttr(node, constants_1.PH_ATTRS.boundaryId); };
    CheckUtils.isPhOrBoundary = function (node) {
        return CheckUtils.isBoundary(node) || CheckUtils.isPlaceholder(node);
    };
    CheckUtils.isBoundaryContent = function (node) {
        return CheckUtils.hasClassName(node, constants_1.BOUNDARY_CLASS_NAMES.content);
    };
    CheckUtils.isBoundaryRight = function (node) { return CheckUtils.hasClassName(node, constants_1.BOUNDARY_CLASS_NAMES.right); };
    CheckUtils.isBoundaryLeft = function (node) { return CheckUtils.hasClassName(node, constants_1.BOUNDARY_CLASS_NAMES.left); };
    /* wrapper */
    CheckUtils.isBlockWrapper = function (node) { return CheckUtils.hasClassName(node, 'block-wrapper'); };
    CheckUtils.isSectionWrapper = function (node) { return CheckUtils.hasClassName(node, 'section-wrapper'); };
    CheckUtils.isWrappable = function (node) { return !!CheckUtils.getWrappableAttr(node); };
    CheckUtils.isWrapBlock = function (node) { return CheckUtils.hasClassName(node, 'block-wrapper'); };
    CheckUtils.isWrappableList = function (node) { return CheckUtils.getWrappableAttr(node) === 'list'; };
    CheckUtils.isWrappableTableCell = function (node) { return CheckUtils.getWrappableAttr(node) === 'table-cell'; };
    CheckUtils.isWrappableBlock = function (node) { return CheckUtils.getWrappableAttr(node) === 'block'; };
    CheckUtils.isListTagName = function (tagName) { return tagName === 'OL' || tagName === 'UL'; };
    CheckUtils.isTagName = function (node, tagName) {
        return TypeUtils_1.TypeUtils.isElement(node) && node.tagName.toLowerCase() === tagName.toLowerCase();
    };
    /* paragraph */
    CheckUtils.isDivParagraph = function (node) {
        return CheckUtils.isParagraphNode(node) && node.tagName.toLowerCase() === 'div';
    };
    CheckUtils.isLiParagraph = function (node) {
        return CheckUtils.isParagraphNode(node) && node.tagName.toLowerCase() === 'li';
    };
    // нужно проверять и атрибут, и класс из-за старой разметки
    CheckUtils.isParagraphNode = function (node) {
        return CheckUtils.getElementAttr(node, constants_1.ATTR_NAMES.paragraph) === 'true' &&
            CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.textParagraph);
    };
    /* tagName */
    CheckUtils.isStyledNode = function (node) {
        return TypeUtils_1.TypeUtils.isElement(node) && constants_1.STYLE_TAG_NAMES.includes(node.tagName);
    };
    CheckUtils.isSpanNode = function (node) { return TypeUtils_1.TypeUtils.isElement(node) && node.tagName === 'SPAN'; };
    CheckUtils.isValidNodeInParagraph = function (node) {
        return TypeUtils_1.TypeUtils.isNode(node) && (node.nodeType === Node.ELEMENT_NODE || node.nodeType === Node.TEXT_NODE);
    };
    return CheckUtils;
}());
exports.CheckUtils = CheckUtils;
