"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENTITY_TYPES = void 0;
exports.ENTITY_TYPES = {
    //
    // ОБЩИЕ ДЛЯ ВСЕХ РОЛЕЙ
    //
    /**
     * Библиотечные блоки
     */
    library: 'library',
    /**
     * Приложение
     */
    attachment: 'attachment',
    /**
     * Списки
     */
    templatedList: 'templatedList',
    //
    // ШАБЛОНЫ ДОГОВОРНЫХ ДОКУМЕНТОВ
    //
    /**
     * Договора
     */
    contract: 'contract',
    /**
     * Дополнительные соглашения
     */
    additionalAgreement: 'additionalAgreement',
    /**
     * Трехсторонние договоры
     */
    cession: 'cession',
    /**
     * Соглашение о расторжении
     */
    terminationAgreement: 'terminationAgreement',
    //
    // ШАБЛОНЫ ДОВЕРЕННОСТЕЙ
    //
    /**
     * Доверенности
     */
    powerOfAttorney: 'powerOfAttorney',
    /**
     * Уведомления об отзыве доверенностей
     */
    revokePowerOfAttorney: 'revokePowerOfAttorney',
    /**
     * Полномочия для доверенностей
     */
    authority: 'authority',
    //
    // ШАБЛОНЫ СУДЕБНЫХ ДОКУМЕНТОВ
    //
    /**
     * Исковые заявления
     */
    statementOfClaim: 'statementOfClaim',
    /**
     * Заявления
     */
    statements: 'statements',
    /**
     * Отзывы на исковые заявления
     */
    revocationStatementOfClaim: 'revocationStatementOfClaim',
    /**
     * Ходатайства
     */
    petitions: 'petitions',
    //
    // СЛУЖЕБНЫЕ СУЩНОСТИ
    //
    document: 'document',
    // [TODO] - Удалить
    folder: 'folder',
    subpart: 'subpart',
    attachmentHeader: 'attachmentHeader',
    attachmentFooter: 'attachmentFooter',
};
exports.default = exports.ENTITY_TYPES;
